/* Register Page Button CSS */
input.sub-btn:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
}
input.sub-btn {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 5px;
  top: 37px;
  border: 1px solid #4f245e;
  background: #4f245e !important;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
  line-height: 1.5;
}
input#submit1 {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 5px;
  top: 37px;
  border: 1px solid #4f245e !important;
  background: #4f245e !important;
  text-align: center;
  margin: 0 auto;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
}
input#submit1:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
}
/* Survey Page Button CSS */
input.submit-btn.survey {
  background: #4f245e;
  outline: none;
  color: #fff;
  padding: 7px 15px !important;
  border: 1px solid #4f245e;
  border-radius: 5px;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
}
input.submit-btn.survey:hover {
  background: #3e3936;
  border: 1px solid #3e3936;
}
input.sub-btn1 {
  background: #4f245e !important;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #4f245e !important;
  border-radius: 5px;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
}

input.sub-btn1:hover {
  background: #3e3936 !important;
  border: 1px solid#3e3936 !important;
}

button#btn1 {
  margin-right: 45%;
  color: #fff !important;
  border: 1px solid #4f245e;
  border-radius: 10px;
  outline: none !important;
  background-color: #4f245e !important;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
}
button#btn1:hover {
  background: #3e3936 !important;
  outline: none !important;
  border: 1px solid #3e3936 !important;
}

a.btn.btn-md.btn-primary {
  position: static !important;
  outline: none !important;
  border-radius: 5px !important;
  height: 46px !important;
  padding: 8px !important;
  width: 118px !important;
  background: #4f245e !important;
  border: 1px solid #4f245e !important;
  color: #fff !important;
  float: right !important;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
}

a.btn.btn-md.btn-primary:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
  text-decoration: none !important;
}

input.sub-btn2 {
  background: #4f245e !important;
  border: 1px solid #4f245e !important;
  outline: none;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;

  line-height: 1.2;
}
input.sub-btn2:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
}
#Thanku_Buttons {
  background-color: #4f245e;
  border-color: #4f245e;
  color: #fff !important;
  text-decoration: none !important;
  /* font-family: "Nimbus Sans" !important; */
  font-family: Roboto, sans-serif !important;
}

#Thanku_Buttons:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
}
